import { useEffect, useState } from 'react';

import { LabelProperties } from 'neo-common-enums';
import { isSameDay } from 'date-fns';

const ROUND_TYPE_GROUP = {
  'round-type-final': 'round-type-final',
  'round-type-bronze-final': 'round-type-final',
  'round-type-fifth-place-decider': 'round-type-final',
  'round-type-seventh-place-decider': 'round-type-final',
  'round-type-semi-final': 'round-type-semi-final',
  'round-type-placement-semi-final': 'round-type-semi-final',
  'round-type-quarter-final': 'round-type-quarter-final',
  'round-type-round-of-16': 'round-type-round-of-16',
  'round-type-qualification': 'round-type-qualification',
  'round-type-first-round': 'round-type-first-round',
};

export const useRoundTypeUuid = ({
  childEvents,
  date,
}: {
  childEvents: any;
  date?: string;
}) => {
  const [roundTypeId, setRoundTypeId] = useState<{
    uuid: string;
    group: string;
    roundTypeUuid: string;
  }>();

  useEffect(() => {
    const notStartedChildEvents = childEvents
      ?.filter(
        (event: any) =>
          date &&
          isSameDay(new Date(event?.date), new Date(date)) &&
          event?.eventStatusId === LabelProperties.EventStatus.NOT_STARTED,
      )
      ?.sort((a: any, b: any) =>
        a?.round === b?.round
          ? a?.time < b?.time
            ? -1
            : 1
          : a?.round < b?.round
          ? -1
          : 1,
      );
    const ongoingChildEvents = childEvents
      ?.filter(
        (event: any) =>
          date &&
          isSameDay(new Date(event?.date), new Date(date)) &&
          event?.eventStatusId === LabelProperties.EventStatus.ONGOING,
      )
      ?.sort((a: any, b: any) =>
        a?.round === b?.round
          ? a?.time < b?.time
            ? -1
            : 1
          : a?.round < b?.round
          ? -1
          : 1,
      );
    const finishedChildEvents = childEvents
      ?.filter(
        (event: any) =>
          date &&
          isSameDay(new Date(event?.date), new Date(date)) &&
          event?.eventStatusId === LabelProperties.EventStatus.FINISHED,
      )
      ?.sort((a: any, b: any) =>
        a?.round === b?.round
          ? a?.time < b?.time
            ? -1
            : 1
          : a?.round < b?.round
          ? -1
          : 1,
      );

    if (ongoingChildEvents?.length) {
      setRoundTypeId({
        uuid: ongoingChildEvents?.[ongoingChildEvents?.length - 1]?.uuid,
        group:
          ROUND_TYPE_GROUP[
            ongoingChildEvents[ongoingChildEvents?.length - 1]?.roundType
              ?.uuid as keyof typeof ROUND_TYPE_GROUP
          ],
        roundTypeUuid:
          ongoingChildEvents?.[ongoingChildEvents?.length - 1]?.roundType?.uuid,
      });
    } else if (finishedChildEvents?.length) {
      setRoundTypeId({
        uuid: finishedChildEvents?.[finishedChildEvents?.length - 1]?.uuid,
        group:
          ROUND_TYPE_GROUP[
            finishedChildEvents[finishedChildEvents?.length - 1]?.roundType
              ?.uuid as keyof typeof ROUND_TYPE_GROUP
          ],
        roundTypeUuid:
          finishedChildEvents?.[finishedChildEvents?.length - 1]?.roundType
            ?.uuid,
      });
    } else if (notStartedChildEvents?.length) {
      setRoundTypeId({
        uuid: notStartedChildEvents[0]?.uuid,
        group:
          ROUND_TYPE_GROUP[
            notStartedChildEvents[0]?.roundType
              ?.uuid as keyof typeof ROUND_TYPE_GROUP
          ],
        roundTypeUuid: notStartedChildEvents?.[0]?.roundType?.uuid,
      });
    }
  }, [childEvents, date]);

  return [roundTypeId, setRoundTypeId] as const;
};

export default useRoundTypeUuid;
