import { COMPONENT_IDS, SPORT_GROUP } from '@ntb-sport/constants';
import { WidgetConfig, SportsdataConfig } from '@ntb-sport/types';
import {
  DATA_TYPES,
  WIDGET_IDS,
  WIDGET_OPTIONS,
  WIDGET_VERSION,
} from '../common';

export const config: WidgetConfig = {
  id: WIDGET_IDS.SPORT_CALENDAR,
  dataType: `${DATA_TYPES.NIFS},${DATA_TYPES.SPORTEDIT}`,
  version: WIDGET_VERSION,
  displayName: 'Kalender',
  widgetNamespace: 'NtbWidgetSportCalendar',
  iframeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.SPORT_CALENDAR}/iframe.html`,
  widgetUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/widgets/${WIDGET_IDS.SPORT_CALENDAR}/${WIDGET_IDS.SPORT_CALENDAR}.js`,
  themeUrl: `https://sportsdata-widgets.ntb.no/v${WIDGET_VERSION}/themes/default.css`,
  title: 'Kalender',
  summary: 'Liste over kamper / konkurranser på en gitt dag',
  sportGroups: [
    SPORT_GROUP.FOOTBALL,
    SPORT_GROUP.HANDBALL,
    SPORT_GROUP.ICE_HOCKEY,
    SPORT_GROUP.ATHLETICS,
    SPORT_GROUP.WINTERSPORTS,
  ],
  description: 'general description to be displayed on the documentation page',
  components: [
    COMPONENT_IDS.SPORT_CALENDAR,
    COMPONENT_IDS.DATE_SELECTOR,
    COMPONENT_IDS.TEAM_CALENDAR,
    COMPONENT_IDS.SPORTEDIT_CALENDAR,
  ],
  options: {
    [WIDGET_OPTIONS.TARGET_ID]: { isRequired: true },
    [WIDGET_OPTIONS.CUSTOMER_ID]: { isRequired: true },
    [WIDGET_OPTIONS.STYLE_ID]: { isRequired: false },
    [WIDGET_OPTIONS.ON_ERROR]: { isRequired: false },
    [WIDGET_OPTIONS.ERROR_MESSAGE]: { isRequired: false },
  },
  uiOptions: {
    [COMPONENT_IDS.SPORT_CALENDAR]: [
      WIDGET_OPTIONS.DEFAULT_DATE,
      WIDGET_OPTIONS.DEFAULT_SPORT_GROUP,
    ],
  },
};

export const sportsdataConfig: SportsdataConfig = {
  dataPickerGroups: {},
  optionGroups: {
    [COMPONENT_IDS.SPORTEDIT_CALENDAR]: {
      title: 'Kalender',
      key: COMPONENT_IDS.SPORTEDIT_CALENDAR,
      options: {
        [WIDGET_OPTIONS.DEFAULT_DATE]: {
          key: WIDGET_OPTIONS.DEFAULT_DATE,
          defaultValue: '',
          label: 'Default dato',
          type: 'input',
        },
        [WIDGET_OPTIONS.DEFAULT_SPORT_GROUP]: {
          key: WIDGET_OPTIONS.DEFAULT_SPORT_GROUP,
          defaultValue: 'football',
          label: 'Default sportgruppe',
          type: 'input',
        },
      },
    },
  },
};
