import { useContext, useState } from 'react';
import * as S from './CustomerDevTools.styled';
import { NifsCustomerContext } from './NifsCustomerProvider';
import { CUSTOMERS } from './Customers';
import { CloseIcon, SettingsIcon } from '@chakra-ui/icons';
import {
  Badge,
  Select,
  Box,
  Flex,
  IconButton,
  Text,
  Stack,
} from '@chakra-ui/react';

interface CustomerDevToolsProps {
  initialIsOpen: boolean;
  onChange: any;
}

export const CustomerDevTools = ({
  initialIsOpen,
  onChange,
}: CustomerDevToolsProps) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  const { nifsCustomer } = useContext(NifsCustomerContext);

  if (typeof window !== 'undefined') {
    if (
      window.location.hostname !== 'localhost' &&
      window.location.hostname !== 'livecenter.test.ntb.dev'
    )
      return null;
  }

  const onToggle = () => {
    setIsOpen((prevValue) => !prevValue);
  };

  const onChangeCustomer = (e: any) => {
    onChange(e.target.value);
  };

  const selectedCustomer = CUSTOMERS?.find(
    (customer) => customer?.host === nifsCustomer?.customer?.host,
  );

  const stageComments = nifsCustomer?.customerStages?.reduce(
    (acc: any, stage: any) => {
      if (nifsCustomer?.includeStageIds?.includes(stage?.stage?.id)) {
        acc.showComments.push(stage?.stage);
      }
      if (nifsCustomer?.excludeStageIds?.includes(stage?.stage?.id)) {
        acc.hideComments.push(stage?.stage);
      }

      return acc;
    },
    { showComments: [], hideComments: [] },
  );

  return (
    <S.Container>
      {isOpen && (
        <S.Aside>
          <S.Header>
            <h2>Nifs</h2>
            <IconButton
              size="xs"
              aria-label="lukk"
              icon={<CloseIcon />}
              onClick={onToggle}
            />
          </S.Header>
          <S.Content>
            <Select
              onChange={onChangeCustomer}
              defaultValue={selectedCustomer?.host}
              mb={2}
            >
              {CUSTOMERS?.map((customer) => (
                <option key={customer?.id} value={customer?.host}>
                  {customer?.name}
                </option>
              ))}
            </Select>
            <Box borderWidth="1px" borderRadius="lg" p={2} mb={2}>
              <dl>
                <div>
                  <dt>Name</dt>
                  <dd>
                    <Badge fontSize={'xs'}>
                      {nifsCustomer?.customer?.name}
                    </Badge>
                  </dd>
                </div>
                <div>
                  <dt>Id</dt>
                  <dd>
                    <Badge fontSize={'xs'}>{nifsCustomer?.customer?.id}</Badge>
                  </dd>
                </div>
                <div>
                  <dt>CustomerConcernId</dt>
                  <dd>
                    <Badge fontSize={'xs'}>
                      {nifsCustomer?.customer?.customerConcernId}
                    </Badge>
                  </dd>
                </div>
                <div>
                  <dt>Host</dt>
                  <dd>
                    <Badge fontSize={'xs'}>
                      {nifsCustomer?.customer?.host}
                    </Badge>
                  </dd>
                </div>
                <div>
                  <dt>Sports</dt>
                  <dd>
                    {nifsCustomer?.customer?.sports?.map((sport) => (
                      <Badge key={sport?.name} fontSize={'xs'}>
                        {sport?.name}
                      </Badge>
                    ))}
                  </dd>
                </div>
              </dl>
            </Box>
            <Box borderWidth="1px" borderRadius="lg" p={2}>
              {Boolean(stageComments?.showComments?.length) && (
                <div>
                  <div>Display comments for</div>
                  <div>
                    {stageComments?.showComments?.map((stage: any) => (
                      <Badge key={stage?.id} fontSize={'xs'}>
                        {stage?.fullName}
                      </Badge>
                    ))}
                  </div>
                </div>
              )}

              {Boolean(!stageComments?.showComments?.length) &&
                Boolean(stageComments?.hideComments?.length) && (
                  <dl>
                    <dt>Hide comments for</dt>
                    <dd>
                      {stageComments?.hideComments?.map((stage: any) => (
                        <Badge key={stage?.id} fontSize={'xs'}>
                          {stage?.fullName}
                        </Badge>
                      ))}
                    </dd>
                  </dl>
                )}
            </Box>
            <Flex columnGap={2}>
              <Stack
                borderWidth="1px"
                borderRadius="lg"
                p={2}
                background={'#fff'}
              >
                <Text>Brand Colors</Text>
                <Box
                  width={50}
                  height={50}
                  bg={'var(--base-colors-brand-1000-background-color)'}
                  color={'var(--base-colors-brand-1000-font-color)'}
                >
                  {'1000'}
                </Box>
                <Box
                  width={50}
                  height={50}
                  bg={'var(--base-colors-brand-900-background-color)'}
                  color={'var(--base-colors-brand-900-font-color)'}
                >
                  {'900'}
                </Box>
                <Box
                  width={50}
                  height={50}
                  bg={'var(--base-colors-brand-800-background-color)'}
                  color={'var(--base-colors-brand-800-font-color)'}
                >
                  {'800'}
                </Box>
                <Box
                  width={50}
                  height={50}
                  bg={'var(--base-colors-brand-700-background-color)'}
                  color={'var(--base-colors-brand-700-font-color)'}
                >
                  {'700'}
                </Box>
                <Box
                  width={50}
                  height={50}
                  bg={'var(--base-colors-brand-600-background-color)'}
                  color={'var(--base-colors-brand-600-font-color)'}
                >
                  {'600'}
                </Box>
                <Box
                  width={50}
                  height={50}
                  bg={'var(--base-colors-brand-500-background-color)'}
                  color={'var(--base-colors-brand-500-font-color)'}
                >
                  {'500'}
                </Box>
                <Box
                  width={50}
                  height={50}
                  bg={'var(--base-colors-brand-400-background-color)'}
                  color={'var(--base-colors-brand-400-font-color)'}
                >
                  {'400'}
                </Box>
                <Box
                  width={50}
                  height={50}
                  bg={'var(--base-colors-brand-300-background-color)'}
                  color={'var(--base-colors-brand-300-font-color)'}
                >
                  {'300'}
                </Box>
                <Box
                  width={50}
                  height={50}
                  bg={'var(--base-colors-brand-200-background-color)'}
                  color={'var(--base-colors-brand-200-font-color)'}
                >
                  {'200'}
                </Box>
                <Box
                  width={50}
                  height={50}
                  bg={'var(--base-colors-brand-100-background-color)'}
                  color={'var(--base-colors-brand-100-font-color)'}
                >
                  {'100'}
                </Box>

                <Box width={50} height={50} bg={'#fff'}>
                  {'white'}
                </Box>
              </Stack>
            </Flex>
          </S.Content>
        </S.Aside>
      )}
      {!isOpen && (
        <S.Toggle onClick={onToggle}>
          <SettingsIcon boxSize={6} />
        </S.Toggle>
      )}
    </S.Container>
  );
};
