import { WidgetDocumentationConfig } from '@ntb-sport/types';

export const WIDGET_VERSION = 3;

export const WIDGET_OPTIONS = {
  // common and required options
  CUSTOMER_ID: 'customerId',
  ERROR_MESSAGE: 'errorMessage',
  ID: 'id',
  MATCH_ID: 'matchId', // support for older versions, do we need it?
  ON_ERROR: 'onError',
  PRODUCT_ID: 'productId', // feedback schema spesific to target appropriate slack webhook
  SPORT_GROUP: 'sportGroup',
  STAGE_ID: 'stageId', // support for older versions, do we need it?
  STYLE_ID: 'styleId',
  TARGET_ID: 'targetId',

  // ui and data options
  DEFAULT_DATE: 'defaultDate',
  DEFAULT_TAB: 'defaultTab',
  DEFAULT_SPORT_GROUP: 'defaultSportGroup',
  GROUP_FIXTURES_BY: 'groupFixturesBy',
  LOGO_URL: 'logoUrl',
  ROUND: 'round',
  SHOW_BACKGROUND_IMAGE: 'showBackgroundImage',
  SHOW_DISTRICT_NAME: 'showDistrictName',
  SHOW_IMAGES: 'showImages',
  SHOW_LOGO: 'showLogo',
  SHOW_MENU: 'showMenu',
  SHOW_NATIONALITY: 'showNationality',
  SHOW_SEARCH: 'showSearch',
  SHOW_STAGE_NAME: 'showStageName',
  SHOW_TABLE: 'showTable',
  SHOW_TEAM_COLORS: 'showTeamColors',
  SHOW_TABLE_INFO: 'showTableInfo',
  SHOW_STADIUM: 'showStadium',
  SHOULD_FOCUS: 'shouldFocus',
  TITLE: 'title',
  // We should put some thought into naming of the following ones
  HIGHLIGHT_FIRST: 'highlightFirst',
  HIGHLIGHTED_TEAMS: 'highlightedTeams',
  MINUTES_DELAY: 'minutesDelay',
  MIN_NUMBER_OF_TEAMS: 'minNumberOfTeams', // spesific filter for fair play table
  REQUIRE_SENIOR_TEAM: 'requireSeniorTeam', // spesific filter for fair play table
  SHOW_ADDITIONAL_ROWS: 'showAdditionalRows',
  MENU_ITEMS: 'menuItems',
  TABLE_COLUMNS: 'tableColumns',
  TABLE_ROWS: 'tableRows',
  TENANT_KEY: 'tenantKey',
};

export const WIDGET_IDS = {
  CUSTOMER_FEEDBACK: 'widget-customer-feedback',
  EVENT_RESULT: 'widget-event-result',
  EXAMPLE: 'widget-example',
  FAIR_PLAY_TABLE: 'widget-fair-play-table',
  MATCH_LINEUP: 'widget-match-lineup',
  MATCH_RESULT: 'widget-match-result',
  MEDAL_TABLE: 'widget-medal-table',
  NEXT_MATCH: 'widget-next-match',
  SEASON_FIXTURES: 'widget-season-fixtures',
  SEASON_STANDINGS: 'widget-season-standings',
  SPORT_CALENDAR: 'widget-sport-calendar',
  TABLE: 'widget-table',
  TEAM_FIXTURES: 'widget-team-fixtures',
  TOP_LIST: 'widget-top-list',
  LIVE_FEED: 'widget-live-feed',
  EVENT_CALENDAR: 'widget-event-calendar',
  MULTISPORT_GENERIC: 'widget-multisport-generic',
  MEDAL_TABLE_OLYMPICS: 'widget-medal-table-olympics',
};

export const WIDGET_ID_TO_DISPLAY_NAME = {
  [WIDGET_IDS.CUSTOMER_FEEDBACK]: 'Customer Feedback',
  [WIDGET_IDS.EVENT_RESULT]: 'Event Result',
  [WIDGET_IDS.EXAMPLE]: 'Example',
  [WIDGET_IDS.FAIR_PLAY_TABLE]: 'Fair Play Table',
  [WIDGET_IDS.MATCH_LINEUP]: 'Match Lineup',
  [WIDGET_IDS.MATCH_RESULT]: 'Match Result',
  [WIDGET_IDS.MEDAL_TABLE]: 'Medal Table',
  [WIDGET_IDS.NEXT_MATCH]: 'Next Match',
  [WIDGET_IDS.SEASON_FIXTURES]: 'Season Fixtures',
  [WIDGET_IDS.SEASON_STANDINGS]: 'Season Standings',
  [WIDGET_IDS.SPORT_CALENDAR]: 'Sport Calendar',
  [WIDGET_IDS.TABLE]: 'Table',
  [WIDGET_IDS.TEAM_FIXTURES]: 'Team Fixtures',
  [WIDGET_IDS.TOP_LIST]: 'Top List',
  [WIDGET_IDS.EXAMPLE]: 'Example Widget',
  [WIDGET_IDS.EVENT_CALENDAR]: 'Event Calendar',
  [WIDGET_IDS.LIVE_FEED]: 'Live Feed',
  [WIDGET_IDS.MULTISPORT_GENERIC]: 'Multisport Generic',
};

export const SMART_UI_IDS = {
  [WIDGET_IDS.CUSTOMER_FEEDBACK]: 'CustomerFeedback',
  [WIDGET_IDS.EVENT_RESULT]: 'EventResult',
  [WIDGET_IDS.FAIR_PLAY_TABLE]: 'FairPlayTable',
  [WIDGET_IDS.MATCH_LINEUP]: 'MatchLineup',
  [WIDGET_IDS.MATCH_RESULT]: 'MatchResult',
  [WIDGET_IDS.MEDAL_TABLE]: 'MedalTable',
  [WIDGET_IDS.NEXT_MATCH]: 'NextMatch',
  [WIDGET_IDS.SEASON_FIXTURES]: 'SeasonFixtures',
  [WIDGET_IDS.SEASON_STANDINGS]: 'SeasonStandings',
  [WIDGET_IDS.SPORT_CALENDAR]: 'SportCalendar',
  [WIDGET_IDS.TABLE]: 'Table',
  [WIDGET_IDS.TEAM_FIXTURES]: 'TeamFixtures',
  [WIDGET_IDS.TOP_LIST]: 'TopList',
  [WIDGET_IDS.EXAMPLE]: 'Example',
  [WIDGET_IDS.TOP_LIST]: 'TopList',
  [WIDGET_IDS.EVENT_CALENDAR]: 'EventCalendar',
  [WIDGET_IDS.LIVE_FEED]: 'LiveFeed',
  [WIDGET_IDS.MULTISPORT_GENERIC]: 'MultisportGeneric',
  [WIDGET_IDS.MEDAL_TABLE_OLYMPICS]: 'MedalTableOlympics',
};

export const DATA_TYPES = {
  EXAMPLE: 'example',
  NIFS: 'nifs',
  SPORTEDIT: 'sportedit',
  NONE: 'none',
};

// we should consider if we need to add an example property for each widget option
// with an example value to display in the documentation page
export const WIDGET_DOCUMENTATION_CONFIG: WidgetDocumentationConfig = {
  [WIDGET_OPTIONS.CUSTOMER_ID]: {
    title: 'customerId',
    types: ['number', 'string'],
    description:
      'This value should be set to your internal ntb customer id. This is of high importance to make sure the widget is configured correctly. If you are not sure if you have the correct id please contact us.',
    required: true,
    ui: false,
    documentationExample: '******',
  },
  [WIDGET_OPTIONS.ERROR_MESSAGE]: {
    title: 'errorMessage',
    types: ['string'],
    description:
      'This property can be used to provide a customized error message to the end users if the widget is not loaded correctly.',
    required: false,
    ui: false,
    documentationExample: 'Something went wrong, please try again later.',
  },
  [WIDGET_OPTIONS.ID]: {
    title: 'id',
    types: ['number', 'string'],
    description:
      'The id of the entity to fetch data from. The entity might be a match, stage, person, competition etc.',
    required: true,
    ui: false,
    documentationExample: '1813521',
  },
  [WIDGET_OPTIONS.ON_ERROR]: {
    title: 'onError',
    types: ['function'],
    description:
      'Used to customize your own error handling callback function. This can for example be used to provide additional logging in your system if anything fails in a widget.',
    required: false,
    ui: false,
    options: {
      params: {
        error: 'Error',
        errorInfo: 'ErrorInfo',
      },
    },
    reference: 'onError(error, errorInfo) { ... }',
    documentationExample: '(error, errorInfo) => {  <insert code here>}',
  },
  [WIDGET_OPTIONS.PRODUCT_ID]: {
    title: 'productId',
    types: ['string'],
    description:
      'Defines which product the feedback schema is related to. Can be used to configure different products to send feedback results to different channels.',
    required: false,
    ui: false,
    documentationExample: '123456',
  },
  [WIDGET_OPTIONS.SPORT_GROUP]: {
    title: 'sportGroup',
    types: ['string'],
    description:
      'Defines the sportgroup that this widget is associated with. This is applied to make sure the widget layout fits the format of the fetched data.',
    required: true,
    ui: false,
    documentationExample: 'football',
  },
  [WIDGET_OPTIONS.STAGE_ID]: {
    title: 'stageId',
    types: ['number'],
    description:
      'The id of the stage or competition to fetch data from. This property is only present to support legacy cases and should not be used in new implementations.',
    required: false,
    ui: false,
    documentationExample: '123456',
  },
  [WIDGET_OPTIONS.STYLE_ID]: {
    title: 'styleId',
    types: ['string'],
    description:
      'This value is used to load custom styling themes, and are intended for customers that have set up their own style themes in our system. For most widget implementations it is recommended to omit this attribute or set the value to "default".',
    required: false,
    ui: false,
    documentationExample: 'default',
  },
  [WIDGET_OPTIONS.TARGET_ID]: {
    title: 'targetId',
    types: ['string'],
    description:
      'Should be equal to the data-target-id of the HTML element which the widget should be rendered inside.',
    required: true,
    ui: false,
    documentationExample: 'test',
  },
  [WIDGET_OPTIONS.DEFAULT_DATE]: {
    title: 'defaultDate',
    types: ['string'],
    description:
      'Specify which date is selected on the initial render of the widget.',
    required: false,
    ui: true,
    documentationExample: '2023-01-01',
  },
  [WIDGET_OPTIONS.DEFAULT_TAB]: {
    title: 'defaultTab',
    types: ['number'],
    description:
      'Specify which tab is selected on the initial render of the widget.',
    required: false,
    ui: true,
    documentationExample: '0',
  },
  [WIDGET_OPTIONS.DEFAULT_SPORT_GROUP]: {
    title: 'defaultSportGroup',
    types: ['string'],
    description:
      'Specify which sport group is selected on the initial render of the widget.',
    required: false,
    ui: true,
    documentationExample: 'football',
  },
  [WIDGET_OPTIONS.GROUP_FIXTURES_BY]: {
    title: 'groupFixturesBy',
    types: ['string'],
    description: 'Group the fixtures by the given value.',
    required: false,
    ui: true,
    documentationExample: 'date',
  },
  [WIDGET_OPTIONS.LOGO_URL]: {
    title: 'logoUrl',
    types: ['string'],
    description:
      'Url to custom logo. No logo or default logo will be shown if omitted.',
    required: false,
    ui: true,
    documentationExample: 'https://www.somedomain.com/logo.png',
  },
  [WIDGET_OPTIONS.HIGHLIGHT_FIRST]: {
    title: 'highlightFirst',
    types: ['boolean'],
    description:
      'Highlights the first item in a list, typically used in a toplists or tables.',
    required: false,
    ui: true,
    documentationExample: 'true',
  },
  [WIDGET_OPTIONS.HIGHLIGHTED_TEAMS]: {
    title: 'highlightedTeams',
    types: ['array'],
    arrayItems: 'number',
    description:
      'An array of team ids to be highlighet if the exists in the given selection. Typically used in tables.',
    required: false,
    ui: true,
    documentationExample: '123456',
  },
  [WIDGET_OPTIONS.MINUTES_DELAY]: {
    title: 'minutesDelay',
    types: ['number'],
    description:
      'The number of minutes to delay updating the widget match data to the next match, after the given match has started. By default the widget will show the next match to be played the same second that the previous next match starts. For example inserting 120 will show the current match as ongoing, and then show the match result for 30 minutes minus added time. 120 minutes after the match started, it will change to show the next match for the given team.',
    required: false,
    ui: false,
    documentationExample: '120',
  },
  [WIDGET_OPTIONS.MIN_NUMBER_OF_TEAMS]: {
    title: 'minNumberOFTeams',
    types: ['number'],
    description:
      'Set an inclusion criteria to only include clubs with at least this number of teams in the displayed data. This property is spesific for the fair play table widget.',
    required: false,
    ui: false,
    documentationExample: '10',
  },
  [WIDGET_OPTIONS.REQUIRE_SENIOR_TEAM]: {
    title: 'requireSeniorTeam',
    types: ['boolean'],
    description:
      'Set an inclusion criteria to only include clubs with at least one senior team in the displayed data. This property is spesific for the fair play table widget.',
    required: false,
    ui: false,
    documentationExample: 'true',
  },
  [WIDGET_OPTIONS.ROUND]: {
    title: 'round',
    types: ['number'],
    description:
      'A spesific tournament or competition round number to show the results, tables, fixtures etc from.',
    required: false,
    ui: false,
    documentationExample: 'active',
  },
  [WIDGET_OPTIONS.SHOW_ADDITIONAL_ROWS]: {
    title: 'showAdditionalRows',
    types: ['boolean'],
    description:
      'Toggle the end users option to expand the initial list on/off.',
    required: false,
    ui: true,
    documentationExample: 'true',
  },
  [WIDGET_OPTIONS.SHOW_BACKGROUND_IMAGE]: {
    title: 'showBackgroundImage',
    types: ['boolean'],
    description: 'Toggle background image on/off.',
    required: false,
    ui: true,
    documentationExample: 'true',
  },
  [WIDGET_OPTIONS.SHOW_DISTRICT_NAME]: {
    title: 'showDistrictName',
    types: ['boolean'],
    description: 'Toggle district name on/off.',
    required: false,
    ui: true,
    documentationExample: 'true',
  },
  [WIDGET_OPTIONS.SHOW_IMAGES]: {
    title: 'showImages',
    types: ['boolean'],
    description: 'Toggle images on/off.',
    required: false,
    ui: true,
    documentationExample: 'true',
  },
  [WIDGET_OPTIONS.SHOW_LOGO]: {
    title: 'showLogo',
    types: ['boolean'],
    description: 'Toggle logo on/off.',
    required: false,
    ui: true,
    documentationExample: 'true',
  },
  [WIDGET_OPTIONS.SHOW_MENU]: {
    title: 'showMenu',
    types: ['boolean'],
    description: 'Toggle the menu on/off.',
    required: false,
    ui: true,
    documentationExample: 'true',
  },
  [WIDGET_OPTIONS.SHOW_NATIONALITY]: {
    title: 'showNationality',
    types: ['boolean'],
    description: 'Toggle nationality on/off.',
    required: false,
    ui: true,
    documentationExample: 'true',
  },
  [WIDGET_OPTIONS.SHOW_SEARCH]: {
    title: 'showSearch',
    types: ['boolean'],
    description: 'Toggle the searchfield on/off.',
    required: false,
    ui: true,
    documentationExample: 'true',
  },
  [WIDGET_OPTIONS.SHOW_STADIUM]: {
    title: 'showStadium',
    types: ['boolean'],
    description: 'Toggle the stadium name on/off.',
    required: false,
    ui: true,
    documentationExample: 'false',
  },
  [WIDGET_OPTIONS.SHOW_STAGE_NAME]: {
    title: 'showStageName',
    types: ['boolean'],
    description: 'Toggle stage name on/off.',
    required: false,
    ui: true,
    documentationExample: 'true',
  },
  [WIDGET_OPTIONS.SHOW_TABLE]: {
    title: 'showTable',
    types: ['boolean'],
    description: 'Toggle the table on/off.',
    required: false,
    ui: true,
    documentationExample: 'true',
  },
  [WIDGET_OPTIONS.SHOW_TEAM_COLORS]: {
    title: 'showTeamColors',
    types: ['boolean'],
    description: 'Toggle team colors on/off.',
    required: false,
    ui: true,
    documentationExample: 'true',
  },
  [WIDGET_OPTIONS.MENU_ITEMS]: {
    title: 'menuItems',
    types: ['array'],
    arrayItems: 'string',
    description:
      'Customize which options should be avaiable to pick from withing a tab or menu.',
    required: false,
    ui: true,
    documentationExample: '[]',
  },
  [WIDGET_OPTIONS.TABLE_COLUMNS]: {
    title: 'tableColumns',
    types: ['object'],
    objectSpecification: {
      keyType: 'string',
      value: {
        type: 'array',
        arrayItems: 'string',
      },
    },
    description:
      'Provide an object to specify which table columns should by visible for each type tab option. The key should be equal to a provided tabOption and the value should be an array of strings that define which columns should be shown for the given tabOption.',
    required: false,
    ui: true,
    documentationExample: '{}',
  },
  [WIDGET_OPTIONS.TABLE_ROWS]: {
    title: 'tableRows',
    types: ['number'],
    description:
      'Specify the maximum number of rows to be shown on the initial render of the widget. Used to limit lists or tables with a large number of items.',
    required: false,
    ui: true,
    documentationExample: '10',
  },
  [WIDGET_OPTIONS.TITLE]: {
    title: 'title',
    types: ['string'],
    description: 'Customize the component title.',
    required: false,
    ui: true,
    documentationExample: 'Example title',
  },
};
