import { useEffect, useState } from 'react';

export interface ScopeType {
  hasScopeResults: boolean;
  name: string;
  number: number;
  scopeTypeId: number;
  scopeCategoryId: number;
  uuid: string;
}

export type ScopesType = Array<ScopeType>;

export const useScopeId = ({
  eventId,
  scopes,
}: {
  eventId: string;
  scopes: ScopesType;
}) => {
  const [scope, setScope] = useState<{
    scopeId: string;
    scopeNumber: number;
    scopeTypeId: number;
    scopeCategoryId?: number;
  }>();

  useEffect(() => {
    if (scopes) {
      const scopesWithResults = scopes?.filter(
        (scope: ScopeType) =>
          scope?.hasScopeResults && scope?.scopeTypeId !== 3,
      );
      if (scopesWithResults?.length) {
        const sortedScopeResults = scopesWithResults?.sort(
          (a: ScopeType, b: ScopeType) => (a?.number < b?.number ? -1 : 1),
        );
        setScope({
          scopeId: sortedScopeResults[sortedScopeResults?.length - 1]?.uuid,
          scopeNumber:
            sortedScopeResults[sortedScopeResults?.length - 1]?.number,
          scopeTypeId:
            sortedScopeResults[sortedScopeResults?.length - 1]?.scopeTypeId,
          scopeCategoryId:
            sortedScopeResults[sortedScopeResults?.length - 1]?.scopeCategoryId,
        });
      } else {
        setScope({
          scopeId: 'eventParticipants',
          scopeNumber: 0,
          scopeTypeId: -1,
          scopeCategoryId: -1,
        });
      }
    }
  }, [eventId, scopes]);

  return [scope, setScope] as const;
};

export default useScopeId;
