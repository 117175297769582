import { useEffect, useState } from 'react';

import { LabelProperties } from 'neo-common-enums';

export const useDisciplineUuid = ({ childEvents }: { childEvents: any }) => {
  const [disciplineId, setDisciplineId] = useState<{
    uuid: string;
  }>();

  useEffect(() => {
    const notStartedChildEvents = childEvents
      ?.filter(
        (event: any) =>
          event?.eventStatusId === LabelProperties.EventStatus.NOT_STARTED
      )
      ?.sort((a: any, b: any) =>
        a?.round === b?.round
          ? a?.time < b?.time
            ? -1
            : 1
          : a?.round < b?.round
          ? -1
          : 1
      );
    const ongoingChildEvents = childEvents
      ?.filter(
        (event: any) =>
          event?.eventStatusId === LabelProperties.EventStatus.ONGOING
      )
      ?.sort((a: any, b: any) =>
        a?.round === b?.round
          ? a?.time < b?.time
            ? -1
            : 1
          : a?.round < b?.round
          ? -1
          : 1
      );
    const finishedChildEvents = childEvents
      ?.filter(
        (event: any) =>
          event?.eventStatusId === LabelProperties.EventStatus.FINISHED
      )
      ?.sort((a: any, b: any) =>
        a?.round === b?.round
          ? a?.time < b?.time
            ? -1
            : 1
          : a?.round < b?.round
          ? -1
          : 1
      );

    if (ongoingChildEvents?.length) {
      setDisciplineId({
        uuid: ongoingChildEvents?.[ongoingChildEvents?.length - 1]?.discipline
          ?.uuid,
      });
    } else if (finishedChildEvents?.length) {
      setDisciplineId({
        uuid: finishedChildEvents?.[finishedChildEvents?.length - 1]?.discipline
          ?.uuid,
      });
    } else if (notStartedChildEvents?.length) {
      setDisciplineId({
        uuid: notStartedChildEvents?.[0]?.discipline?.uuid,
      });
    }
  }, [childEvents]);

  return [disciplineId, setDisciplineId] as const;
};

export default useDisciplineUuid;
